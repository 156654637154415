import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import {
  faBellConcierge, faChurch, faHourglassEnd, faMusic, faUtensils
} from '@fortawesome/free-solid-svg-icons';
import 'react-vertical-timeline-component/style.min.css';
import './Timeline.scss';


const Timeline = () => {
  const churchIcon = {
    icon: <FontAwesomeIcon icon={faChurch} />,
    iconStyle: { background: '#BAA37d', color: '#FFFFFF' },
  };

  const conciergeIcon = {
    icon: <FontAwesomeIcon icon={faBellConcierge} />,
    iconStyle: { background: '#BAA37d', color: '#FFFFFF' },
  };

  const hourglassEndIcon = {
    icon: <FontAwesomeIcon icon={faHourglassEnd} />,
    iconStyle: { background: '#BAA37d', color: '#FFFFFF' },
  };

  const musicIcon = {
    icon: <FontAwesomeIcon icon={faMusic} />,
    iconStyle: { background: '#BAA37d', color: '#FFFFFF' },
  };

  const utensilsIcon = {
    icon: <FontAwesomeIcon icon={faUtensils} />,
    iconStyle: { background: '#BAA37d', color: '#FFFFFF' },
  };

  const timeline = [
    {
      icon: conciergeIcon,
      date: '4:15 p.m.',
      title: 'Recepción'
    },
    {
      icon: churchIcon,
      date: '4:45 p.m.',
      title: 'Ceremonia'
    },
    {
      icon: utensilsIcon,
      date: '6:30 p.m.',
      title: 'Comida'
    },
    {
      icon: musicIcon,
      date: '7:30 p.m.',
      title: 'Baile'
    },
    {
      icon: hourglassEndIcon,
      date: '12:00 p.m.',
      title: 'Fin'
    }
  ];

  return (
    <VerticalTimeline>
      {timeline.map((t, i) => {
        const contentStyle = { background: '#BAA37d', color: '#FFFFFF' };
        const arrowStyle = { borderRight: '10px solid  #BAA37d' } ;

        return (
          <VerticalTimelineElement
            key={i}
            className="vertical-timeline-element--i"
            contentStyle={contentStyle}
            contentArrowStyle={arrowStyle}
            date={t.date}
            {...t.icon}
          >
            {t.title ? (
              <React.Fragment>
                <h5 className="vertical-timeline-element-title">{t.title}</h5>
              </React.Fragment>
            ) : undefined}
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  )
};

export default Timeline;
