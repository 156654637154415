import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../utils/II/Footer/Footer';
import Loader from '../../utils/II/Loader/Loader';
import LogoSvg from '../../svg/II/Logo';

interface LoginPageProps {
  onLogin: (valid: boolean) => void;
}

const Login = ({ onLogin }: LoginPageProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const handleLogin = () => {
    if (username === 'Laura&Nestor' && password === 'L4ur4&N35t0r') {
      setErrorForm(false);
      onLogin(true);
      navigate('/admin');
    } else {
      setErrorForm(true);
    }
  };

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  return (
    <div className="main-ii" onContextMenu={(e)=> e.preventDefault()}>
      {isLoading ?
        <Loader />
      :
        <>
          <div className="login-section-ii">
            <div className="login-wrapper">
              <LogoSvg />
              <input
                className="input-ii"
                type="text"
                placeholder="Usuario"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                className="input-ii"
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <p>{errorForm ? 'El usuario y la contraseña son incorrectas' : ''}</p>
              <button className="btn-ii" onClick={handleLogin}>Ingresar</button>
            </div>
          </div>
          <Footer />
        </>
      }
    </div>
  );
}

export default Login;
