import React, {useEffect, useState} from 'react';
import './App.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AdminPanel from './pages/II/AdminPanel';
import Home from './pages/Home';
import Login from './pages/II/Login';
import Ticket from './pages/Ticket';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = (valid: boolean) => {
    if (valid) {
      setIsAuthenticated(true);
    }
  };

  function ProtectedRoute({ children }: { children: JSX.Element }) {
    return isAuthenticated ? children : <Navigate to="/login" />;
  }

  useEffect(() => {
    AOS.init();
  }, [])


  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/boleto/:num" element={<Ticket/>}/>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminPanel />
            </ProtectedRoute>
          }
        />
      </Routes>
  </Router>
  );
}

export default App;
