import React from 'react';
import CountdownTimer from '../../utils/CountdownTimer/CountdownTimer';
import './Countdown.scss'

const Countdown = () => {
  const dateTimeAfterThreeDays = 'Nov 30, 2024 16:15:00';

  return (
    <section className="countdown-section" id="home">
      <div className="countdown-info">
        <p className="title tangerine mb-0">Laura & Néstor</p>
        <CountdownTimer targetDate={dateTimeAfterThreeDays} />
      </div>
    </section>
  );
};

export default Countdown
