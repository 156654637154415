import React from 'react';
import { Bars } from 'react-loader-spinner';
import styles from './Loader.module.scss';

const Loader = () => (
  <div className={styles['loader-ii']} data-aos="zoom-in">
    <Bars
      height="80"
      width="80"
      color="#AA508A"
      ariaLabel="Cargando..."
      visible={true}
    />
    Cargando...
  </div>
);

export default Loader;
