import React from 'react';
import AddToCalendar from '../../utils/AddToCalendar/AddToCalendar';
import LoungeImg from '../../assets/img/lounge.jpg';
import styles from './Address.module.scss'

const Address = () => {
  return (
    <section className={styles['address-section']} id="address">
      <div className="container" data-aos="zoom-in">
        <h3 className="cinzel">¿Dónde y Cuándo</h3>

        <div className={`${styles['address-wrapper']} mt-3 card`}>
          <img className={styles['img-border']} height="260" src={LoungeImg} alt="Lounge" />
          <div>
            <h5>Jardín de Fiestas "Fénix"</h5>
            <p className="mb-0"><b>Cuándo:</b> Sábado, 30 de noviembre del 2024.</p>
            <p className="mb-0"><b>Horario:</b> De 16:15 a 00:00 hrs.</p>
            <p><b>Dirección</b>: Calle 10 de Agosto de 1860, #2050-A, Col. Leyes de Reforma 3ra Sección, Alcaldía Iztapalapa, CDMX</p>
            <a href="https://maps.app.goo.gl/1wtnwDQAWdbis4Vo7" target="_blank" rel="noreferrer" className="border-0 mt-3 w-100">
              <button className="btn-gold fill w-100 mb-2">VER MAPA</button>
            </a>

            <AddToCalendar />
          </div>
        </div>
      </div>
  </section>
  );
};

export default Address;