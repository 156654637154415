import React from 'react';
import Divider from '../../utils/Divider/Divider';
import styles from './Description.module.scss'

const Description = () => {
  return (
    <section id="description" className={`${styles['description-section']} bg-double-line bg-leafs`}>
      <div className="container">
        <div data-aos="fade-down">
          <p className="cinzel mb-0">"No fuiste antes ni después, fuiste a tiempo. A tiempo para que me enamorara de ti"</p>
          <p className={styles['author']}>Jaime Sabines</p>
        </div>
        <Divider />
        <div className={styles['persons-wrapper']} data-aos="flip-left">
          <div>
            <p>Tenemos el gusto y placer de invitarte a nuestra ceremonia matrimonial y nos gustaría que formaras parte de este día especial, junto a nuestro padres...</p>
          </div>
          <div className={styles['parents']} >
            <div>
              <p className="mb-0"><b>Padres de la novia:</b></p>
              <p className="mb-0">Armando Alegría V.</p>
              <p className="mb-0">Hilda Tavira R.</p>
            </div>
            <div>
              <p className="mb-0"><b>Padres del novio:</b></p>
              <p className="mb-0">Bernabé Huicochea A.</p>
              <p className="mb-0">Silvia Morales E.</p>
            </div>
          </div>
          <div className={styles['children']}>
            <p className="mb-0">Con amor y acompañados de nuestros hijos</p>
            <p className="mb-0">América Najlin</p>
            <p className="mb-0">Mauricio Habib</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Description