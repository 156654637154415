import React from 'react';
import Divider from '../../utils/Divider/Divider';
import styles from './Measures.module.scss'

const Measures = () => {
  return (
    <section id="measures" className={`${styles['measures-section']} bg-double-line bg-leafs`}>
      <div className="container mb-4" data-aos="fade-up">
        <h3 className="cinzel">Información y Medidas de Seguridad</h3>

        <div className="mt-3 card">
          <p>El salón cuenta con estacionamiento privado con costo extra.</p>
          <p>Puedes llevar tu propia bebida si así lo deseas (tendremos ron y tequila).</p>
          <p>Si asistes con niños, favor de estar pendiente de ellos para evitar accidentes.</p>
        </div>
      </div>
      <Divider />
    </section>
  );
};

export default Measures;