import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: "./photos/1.jpg",
    original: "./photos/1.jpg",
    width: 1202,
    height: 1600,
  },
  {
    src: "./photos/2.jpg",
    original: "./photos/2.jpg",
    width: 1200,
    height: 1600,
  },
  {
    src: "./photos/3.jpg",
    original: "./photos/3.jpg",
    width: 1200,
    height: 1600,
  },
  {
    src: "./photos/4.jpg",
    original: "./photos/4.jpg",
    width: 1080,
    height: 1292,
  },
  {
    src: "./photos/5.jpg",
    original: "./photos/5.jpg",
    width: 1200,
    height: 1600,
  },
  {
    src: "./photos/6.jpg",
    original: "./photos/6.jpg",
    width: 1200,
    height: 1600,
  },
  {
    src: "./photos/7.jpg",
    original: "./photos/7.jpg",
    width: 1200,
    height: 1600,
  },
  {
    src: "./photos/8.jpg",
    original: "./photos/8.jpg",
    width: 1280,
    height: 961,
  },
  {
    src: "./photos/9.jpg",
    original: "./photos/9.jpg",
    width: 4928,
    height: 6560,
  },
  {
    src: "./photos/10.jpg",
    original: "./photos/10.jpg",
    width: 4928,
    height: 6560,
  }
];