import React from 'react';
import FormalIcon from '../../assets/icons/dress-code.png';
import styles from './Dresscode.module.scss';

const Dresscode = () => {
  return (
    <section id="dress-code" className={`${styles['dress-code-section']} bg-green`} data-aos="fade-up">
      <h3 className="cinzel">Código de Vestimenta</h3>
      <div>
        <p className="mb-0">FORMAL</p>
        <img className="img-circle m-1" data-aos="zoom-out" src={FormalIcon} alt="Dress Code" />
      </div>
      <p className="mt-2 mb-0">El blanco queda reservado exclusivamente para la novia</p>
    </section>
  );
};

export default Dresscode;
