import React, {useState} from 'react';
import Address from '../components/Address/Address';
import Confirm from '../components/Confirm/Confirm';
import Countdown from '../components/Countdown/Countdown';
import Description from '../components/Description/Description';
import Dresscode from '../components/Dresscode/Dresscode';
import Gallery from '../components/Gallery/Gallery';
import Footer from '../utils/Footer/Footer';
import Hashtag from '../components/Hashtag/Hashtag';
import Intro from '../components/Intro/Intro';
import Itinerary from '../components/Itinerary/Itinerary';
import Loader from '../utils/Loader/Loader';
import Measures from '../components/Measures/Measures';
import Player from '../utils/Player/Player';
import Presents from '../components/Presents/Presents';
import Sidebar from '../utils/Sidebar/Sidebar';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isIntro, setIsIntro] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  const handleIntro = () => {
    setIsIntro(false)
  }

  return (
    <div className="main" onContextMenu={(e)=> e.preventDefault()}>
      {isLoading ?
        <Loader />
      :
        <>
          {isIntro ?
            <>
              <Intro handleIntro={handleIntro} />
            </>
          :
            <>
              <Player />
              <Sidebar />
              <div data-aos="fade-up" data-aos-duration="3000">
                <Countdown />
                <Description />
                <Address />
                <Dresscode />
                <Measures />
                <Itinerary />
                <Gallery />
                <Presents />
                <Confirm />
                <Hashtag />
                <Footer />
              </div>
            </>
          }
        </>
      }
    </div>
  );
}

export default Home;
