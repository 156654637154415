import React, {useState} from 'react';
import { Spin as Hamburger } from 'hamburger-react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styles from './Sidebar.module.scss';

const Sidebar = () => {
  const [isOpenBurger, setOpenBurger] = useState(false);
  const [showMenu, setShowMenu] = useState(false);


  const handleMenu = () => {
    setShowMenu(!showMenu);
    setOpenBurger(!isOpenBurger);
  }

  return (
    <>
      <button className={styles['burger-btn']} onClick={handleMenu}>
        <Hamburger toggled={isOpenBurger} toggle={setOpenBurger} size={25}/>
      </button>

      <Offcanvas show={showMenu} onHide={handleMenu} backdrop={false} scroll={false}>
        <Offcanvas.Header>
          <h3 className="offcanvas-title monster" id="offcanvas">
            <span>MENÚ</span>
          </h3>
          <i className={`bi bi-x ${styles['close-btn']}`} onClick={handleMenu}></i>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className={`nav ${styles['menu']}`} id="menu">
            <li className="nav-item" onClick={handleMenu}>
              <a href="#home">
                <span>Cuenta Regresiva</span>
              </a>
            </li>
            <li className="nav-item" onClick={handleMenu}>
              <a href="#description">
                <span>Nuestros padres e hijos</span>
              </a>
            </li>
            <li className="nav-item" onClick={handleMenu}>
              <a href="#address">
                <span>¿Dónde y Cuándo?</span>
              </a>
            </li>
            <li className="nav-item" onClick={handleMenu}>
              <a href="#dress-code">
                <span>Código de vestimenta</span>
              </a>
            </li>
            <li className="nav-item" onClick={handleMenu}>
              <a href="#measures">
                <span>Información y Medidas de Seguridad</span>
              </a>
            </li>
            <li className="nav-item" onClick={handleMenu}>
              <a href="#itinerary">
                <span>Itinerario</span>
              </a>
            </li>
            <li className="nav-item" onClick={handleMenu}>
              <a href="#gallery">
                <span>Nuestros Recuerdos</span>
              </a>
            </li>
            <li className="nav-item" onClick={handleMenu}>
              <a href="#presents">
                <span>Mesa de Regalos</span>
              </a>
            </li>
            <li className="nav-item" onClick={handleMenu}>
              <a href="#confirm">
                <span>Confirmación de asistencia</span>
              </a>
            </li>
            <li className="nav-item" onClick={handleMenu}>
              <a href="#hashtag">
                <span>Hashtag</span>
              </a>
            </li>
          </ul>

          <div className={styles['contact']}>
            <div className="d-flex flex-row align-items-center">
              <i className="bi bi-envelope-at-fill"></i>
              <p className="mb-0"><a href="mailto:teinvitasu.party@gmail.com" target="_blank" rel="noopener noreferrer">teinvitasu.party@gmail.com</a></p>
            </div>
            <div className="d-flex flex-row align-items-center">
              <i className="bi bi-instagram"></i>
              <p className="mb-0"><a href="https://www.instagram.com/teinvitaasu.party" target="_blank" rel="noopener noreferrer">@teinvitasu.party</a></p>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default Sidebar;
