import React from 'react';
import styles from './Confirm.module.scss'

const Name = () => {
  return (
    <section id="confirm" className={`${styles['confirm-section']} bg-leafs reverse`} data-aos="fade-down">
      <div className="container">
        <h3 className="cinzel">Confirmación de asistencia</h3>

        <p className="mt-2">Agradecemos nos envíes tu respuesta antes del <b>26 de octubre del presente año</b> a nuestros números de WhatsApp. Si no recibimos tu confirmación, asumiremos que no podrás asistir.</p>
        <p>Al confirmar tu asistencia, te enviaremos los accesos al evento.</p>

        <a href="https://api.whatsapp.com/send/?phone=5539563973&amp;text=Confirmo mi asistencia para tu boda 💍👰‍♀️🤵‍♂️." target="_blank" rel="noreferrer" className="border-0">
          <button className="btn-gold fill mt-2 mb-2">
            <i className="bi bi-whatsapp"></i>
            CONFIRMAR A LAURA
          </button>
        </a>

        <a href="https://api.whatsapp.com/send/?phone=5543950457&amp;text=Confirmo mi asistencia para tu boda 💍👰‍♀️🤵‍♂️." target="_blank" rel="noreferrer" className="border-0">
          <button className="btn-gold fill mt-2 mb-2">
            <i className="bi bi-whatsapp"></i>
            CONFIRMAR A NÉSTOR
          </button>
        </a>
      </div>
    </section>
  );
};

export default Name
