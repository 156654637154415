import React, { useState } from 'react';
import styles from './Intro.module.scss'

const Init = ({ handleIntro}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(true);

  const handleClick = () => {
    setIsOpen(!isOpen);

    setTimeout(() => {
      setShow(false);
      handleIntro(show)
    }, 500);
  };

  return (
    <section className={styles['intro-section']}>
        <div className={`${styles['top-div']} ${isOpen ? styles['open'] : ''}`}></div>
        <button onClick={handleClick} className={styles['toggle-button']}>
          Abrir
        </button>
        <div className={`${styles['bottom-div']} ${isOpen ? styles['open'] : ''}`}></div>
    </section>
  );
};

export default Init;
