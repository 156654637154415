import React from 'react';
import Divider from '../../utils/Divider/Divider';
import Timeline from '../../utils/Timeline/Timeline';
import styles from './Itinerary.module.scss'

const Description = () => {
  return (
    <section id="itinerary" className={styles['itinerary-section']} data-aos="fade-up">
      <div className="container">
        <h3 className="cinzel">Itinerario</h3>

        <Timeline />
      </div>
      <Divider />
    </section>
  );
};

export default Description