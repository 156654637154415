import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from '../../hooks/useCountdown';

const ShowCounter = ({ days, hours, minutes, seconds }: any) => {
  return (
    <>
      <DateTimeDisplay value={days} type={'Días'} />
      <DateTimeDisplay value={hours} type={'Horas'} />
      <DateTimeDisplay value={minutes} type={'Minutos'} />
      <DateTimeDisplay value={seconds} type={'Segundos'} />
    </>
  );
};

const CountdownTimer = ({ targetDate }: any) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  return (
    <ShowCounter
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );
};

export default CountdownTimer;
