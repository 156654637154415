import React from 'react';
import { useParams } from 'react-router-dom';
import Divider from '../../utils/Divider/Divider';
import styles from './Ticket.module.scss'

const Ticket = () => {
  const {num} = useParams();
  const decodedParam = num ? atob(num) : '';

  return (
    <section id="ticket" className={`${styles['ticket-section']} bg-double-line bg-leafs reverse`}>
      <div className="container">
        <h3 className="cinzel mb-0"> Nuestra boda</h3>
        <p className="title tangerine mb-0">Laura & Néstor</p>
        <Divider />
        <p className="cinzel mt-5">Número de boletos: {decodedParam}</p>

        <p className={styles['legend']}>Presentar este boleto para acceso al evento</p>
      </div>
    </section>
  );
};

export default Ticket