import React, {useState} from 'react';
import Footer from '../../utils/II/Footer/Footer';
import Loader from '../../utils/II/Loader/Loader';
import LogoSvg from '../../svg/II/Logo';

const AdminPanel = () => {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  return (
    <div className="main-ii" onContextMenu={(e)=> e.preventDefault()}>
      {isLoading ?
        <Loader />
      :
        <>
          <div className="admin-section-ii">
            <div className="container">
              <LogoSvg />
              <h2 className="title-ii">Panel de Administración</h2>

              <table className="table-ii">
                <thead>
                  <tr>
                    <th>Boletos asignados</th>
                    <th>Enlace</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>https://lauraynestor.teinvitaasu.party/boleto/{btoa(String(1))}</td>
                    <td><a href={`https://lauraynestor.teinvitaasu.party/boleto/${btoa(String(1))}`} target="_blank" rel="noreferrer">Ver</a></td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>https://lauraynestor.teinvitaasu.party/boleto/{btoa(String(2))}</td>
                    <td><a href={`https://lauraynestor.teinvitaasu.party/boleto/${btoa(String(2))}`} target="_blank" rel="noreferrer">Ver</a></td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>https://lauraynestor.teinvitaasu.party/boleto/{btoa(String(3))}</td>
                    <td><a href={`https://lauraynestor.teinvitaasu.party/boleto/${btoa(String(3))}`} target="_blank" rel="noreferrer">Ver</a></td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>https://lauraynestor.teinvitaasu.party/boleto/{btoa(String(4))}</td>
                    <td><a href={`https://lauraynestor.teinvitaasu.party/boleto/${btoa(String(4))}`} target="_blank" rel="noreferrer">Ver</a></td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>https://lauraynestor.teinvitaasu.party/boleto/{btoa(String(5))}</td>
                    <td><a href={`https://lauraynestor.teinvitaasu.party/boleto/${btoa(String(5))}`} target="_blank" rel="noreferrer">Ver</a></td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>https://lauraynestor.teinvitaasu.party/boleto/{btoa(String(6))}</td>
                    <td><a href={`https://lauraynestor.teinvitaasu.party/boleto/${btoa(String(6))}`} target="_blank" rel="noreferrer">Ver</a></td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>https://lauraynestor.teinvitaasu.party/boleto/{btoa(String(7))}</td>
                    <td><a href={`https://lauraynestor.teinvitaasu.party/boleto/${btoa(String(7))}`} target="_blank" rel="noreferrer">Ver</a></td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>https://lauraynestor.teinvitaasu.party/boleto/{btoa(String(8))}</td>
                    <td><a href={`https://lauraynestor.teinvitaasu.party/boleto/${btoa(String(8))}`} target="_blank" rel="noreferrer">Ver</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <Footer />
        </>
    }
    </div>
  );
}

export default AdminPanel;
