import React from 'react';
import Divider from '../../utils/Divider/Divider';
import SearsImg from '../../assets/img/sears.png';
import styles from './Presents.module.scss';

const Present = () => {
  return (
    <section id="presents" className={`${styles['presents-section']} bg-double-line`} data-aos="zoom-in">
      <div className="container">
        <h3 className="cinzel">Mesa de Regalos</h3>
        <p>Tu presencia es nuestro mayor regalo, pero si deseas obsequiarnos algo te adjuntamos una sugerencia.</p>

        <div>
          <img src={SearsImg} alt="Sears" />
          <p>No. Evento 51012067</p>
          <a href="https://www.sears.com.mx/Mesa-de-Regalos/152214/te-invitamos-a-nuestra-boda-nestor-laura" target="_blank" rel="noreferrer" className="border-0">
            <button className="btn-gold fill mt-2 mb-4">Ver mesa</button>
          </a>
        </div>
      </div>
      <Divider />
    </section>
  );
};

export default Present;
