import React, {useEffect, useRef, useState} from 'react';
import { google } from "calendar-link";
import { CalendarEvent } from 'calendar-link';
import GoogleIcon from '../../svg/Google';
import styles from './AddToCalendar.module.scss'

const AddToCalendar = () => {
  const btnRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(btnRef);

  const event: CalendarEvent = {
    title: 'Boda de Laura & Néstor 💍👰🤵',
    start: '2024-11-30 16:15:00',
    duration: [8, 'hour'],
    location: "Salón de Fiestas Fénix",
  };

  const googleUrl = google(event);

  return (
    <div className={styles['atc-btn']} ref={btnRef}>
      <button
        className={`btn-gold fill w-100 ${isOpen ? styles['active'] : ''}`}
        onClick={() => setIsOpen(!isOpen)}>
        AGENDAR EN EL CALENDARIO
      </button>
      { isOpen && (
        <ul className={styles['links-wrapper']}>
          <li>
            <a href={googleUrl} target="_blank" rel="noreferrer" onClick={() => setIsOpen(!isOpen)}>
              <GoogleIcon />
              Google
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default AddToCalendar;