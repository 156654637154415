import React, {useState} from 'react';
import Footer from '../utils/Footer/Footer';
import Loader from '../utils/Loader/Loader';
import Ticket from '../components/Ticket/Ticket';

const Tickets = () => {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  return (
    <div className="main" onContextMenu={(e)=> e.preventDefault()}>
      {isLoading ?
        <Loader />
      :
        <>
          <Ticket />
          <Footer />
        </>
      }
    </div>
  );
}

export default Tickets;
