import React from 'react';
import styles from './Hashtag.module.scss'

const Description = () => {
  return (
    <section id="hashtag" className={styles['hashtag-section']}>
      <h3 className="cinzel">Hashtag, etiqueta y comparte</h3>
      <div className="container">
        <div className={styles['hashtag']}>
          <i className="bi bi-instagram"></i>
          <p className="mb-0">#BodaLaurayNéstor</p>

          <button className="btn-gold fill mt-2 mb-2">
            <a href="https://www.instagram.com/explore/tags/BodaLaurayN%C3%A9stor/" target="_blank" rel="noreferrer" className="border-0">
                Ver en Instagram
            </a>
          </button>
        </div>
        <p>Usa este hashtag en todas las fotos que publiques en redes sociales durante Nuestra Boda. Nos encantará ver este gran día desde tu perspectiva y conservar esos momentos especiales.</p>
      </div>
    </section>
  );
};

export default Description